<script>
  export default {
    components: {
      ItemUserConnected: () => import("@/components/user/usersConnecteds/ItemUserConnected.vue"),
      ContentModels: () => import("@/components/user/usersConnecteds/ContentModels.vue"),
    },
    data() {
      return {
        dicGroups: {
          spark: "spark",
        },
      };
    },
    computed: {
      usersOnline() {
        return this.$store.getters["connectedUsers/getOnlineUsers"] || [];
      },
      usersOffline() {
        const users = this.$store.state.user.userList;
        const usersConected = this.usersOnline;

        return users.filter((user) => !usersConected.some((userConected) => userConected._id === user._id));
      },
      usersInSpark() {
        return this.usersOnline.filter(({ getBy }) => getBy === "spark");
      },
      totalUsers() {
        return this.usersOnline.length + this.usersOffline.length;
      },
      groupsWithUsers() {
        const areas = this.$store.state.user.companyAreas;
        const groups = [...this.usersOnline, ...this.usersOffline].reduce((groupsAcc, user) => {
          const groupToSet = areas.find((area) => area._id === user?.area)?.name || this.dicGroups[user?.role] || "sin area";

          const hasGroup = groupsAcc.find((group) => group?.name === groupToSet);
          if (!hasGroup) {
            groupsAcc.push({ name: groupToSet, users: [user] });
          } else {
            hasGroup.users.push(user);
          }

          return groupsAcc;
        }, []);

        return groups.sort((a, b) => b.users.length - a.users.length);
      },
    },
    methods: {
      async getAreas() {
        const { data } = await this.$axios.get(`/helpdesk/info?type=area`);
        return data?.data || [];
      },
    },
    async mounted() {
      const areas = await this.getAreas();
      this.$store.state.user.companyAreas = areas;
      this.$store.dispatch("connectedUsers/initUsersSpark");
      this.$store.dispatch("user/getUsersList");
    },
  };
</script>
<template>
  <section class="usersConnecteds">
    <div class="usersConnecteds__wrapper" v-if="usersOnline.length">
      <div class="usersConnecteds__titleContainer">
        <h2 class="usersConnecteds__title">Usuarios</h2>
        <div class="usersConnecteds__contentBadge">
          <div class="tool-tip">
            <div class="usersConnecteds__badge usersConnecteds__badge--spark">
              {{ usersInSpark.length }}
            </div>
            <span class="tooltiptext tool-auto">Spark</span>
          </div>
          <div class="tool-tip">
            <div class="usersConnecteds__badge usersConnecteds__badge--online">
              {{ usersOnline.length - usersInSpark.length }}
            </div>
            <span class="tooltiptext tool-auto">Conectados</span>
          </div>
          <div class="tool-tip">
            <div class="usersConnecteds__badge usersConnecteds__badge--offline">
              {{ usersOffline.length }}
            </div>
            <span class="tooltiptext tool-auto">Desconectados</span>
          </div>
          <div class="tool-tip">
            <div class="usersConnecteds__badge usersConnecteds__badge--total">
              {{ totalUsers }}
            </div>
            <span class="tooltiptext tool-auto">Total</span>
          </div>
        </div>
      </div>
      <div class="usersConnecteds__list">
        <component
          class="usersConnecteds__itemList"
          v-for="(group, idx) in groupsWithUsers"
          :key="idx"
          :users="group.users"
          :rowInfo="{ title: group.name }"
          :is="group.name === 'modelos' ? 'ContentModels' : 'ItemUserConnected'"
        ></component>
      </div>
    </div>
    <div class="usersConnecteds__wrapper" v-else>
      <p class="usersConnecteds__msg noFoundInfo">No hay usuarios para mostrar</p>
    </div>
  </section>
</template>
<style lang="scss">
  .usersConnecteds {
    width: 100%;
    max-width: 1440px;
    display: flex;
    justify-content: center;
    &__wrapper {
      width: 100%;
    }
    &__title {
      font-weight: 400;
      font-size: 21px;
    }
    &__titleContainer {
      @include Row(space-between);
      margin: $mpadding/1.5 0;
    }
    &__contentBadge {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
      max-width: 250px;
      gap: 7px;
    }
    &__badge {
      @include Row();
      height: 25px;
      padding: 5px 10px;
      font-size: 16px;
      font-weight: 600;
      color: $white;
      border-radius: 15px;
      &--spark {
        color: #ff8000bc;
        background-color: #fd9b391c;
      }
      &--offline {
        color: $chicago;
        background-color: #5b59571c;
      }
      &--online {
        color: $la_palma;
        background-color: #1f9e0c1f;
      }
      &--total {
        color: $primary-color;
        background-color: #bd090914;
      }
    }
    &__itemList {
      margin: $mpadding/1.5 0;
    }

    @include tabletWidth() {
      &__title {
        font-size: 27px;
      }
      &__badge {
        font-size: 22px;
        padding: 5px 15px;
      }
    }
    @include tvWidth() {
      &__title {
        font-size: 32px;
      }
      &__badge {
        height: 34px;
        line-height: 34px;
        font-size: 22px;
      }
    }
  }
</style>
